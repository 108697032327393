
.caption-id {
  font-size: 12px;
  line-height: 17.5px;
  width: 100%;
  max-width: 52px;
  margin-right: 4px;
}
.has_caption {
  line-height: 34px;
}

.category-product__thumb {
  a:hover {
    opacity: 1;
  }
  box-shadow: 0 0 10px -4px rgba(0,0,0,0.6);
}

.category-product__button {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    opacity: 1;
  }
  box-shadow: 0 0 10px -4px rgba(0,0,0,0.6);
}

.caption-content-wrapper {
  width: 128px;
  white-space: nowrap;
}
.caption-content {
  display: flex;
  align-items: center;
  font-size: 10px;
  height: 100%;
}

@media screen and (max-width: 767.98px){
  .caption-content-wrapper {
    width: calc(100% - 42px);
  }
  .caption-id {
    width: 100%;
    max-width: 42px;
    font-size: 10px;
    margin-right: 2px;
  }
}

